import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { get, put, post } from "../../api/axios";
import Loader from "../../components/Loader";

import { baseUrl } from "../../api/config";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar as MuiAvatar,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Paper,
  Snackbar,
  Box,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Chip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";

import styled from "styled-components/macro";

import { Home, Mail, Phone, User, UserCheck } from "react-feather";
import { LocalHospital } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  tabs: {
    padding: theme.spacing(4),
  },
  flex: {
    display: "flex",
  },
  wrap: {
    flexWrap: "wrap",
  },
  right: {
    marginRight: "10px",
    fontWeight: "bold",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const Spacer = styled.div(spacing);
const Centered = styled.div`
  text-align: center;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const AcceptButton = styled(Button)`
  background-color: #378e3c;
  color: white;
  margin-right: 10px;
  &:hover {
    background-color: #378e3c;
    color: white;
  }
`;

const RejectButton = styled(Button)`
  background-color: #f50057;
  color: white;
  margin-left: 5px;
  &:hover {
    background-color: #f50057;
    color: white;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Details = () => {
  const [loading, setLoading] = useState(true);
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [refund, setRefund] = useState(false);
  const [data, setData] = useState({});
  const [zoomed, setZoomed] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState("");
  const [refundSnack, setRefundSnack] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);
  const [acceptModal, setAcceptModal] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const authReducer = useSelector((state) => state.authReducer);
  useEffect(() => {
    if (id) {
      if (loading) {
        (async () => {
          const { data } = await get(`${baseUrl}/backend/request/${id}`, {
            headers: {
              Authorization: `Bearer ${authReducer.token}`,
            },
          });
          const identityPictures = [];
          identityPictures.push(data.identityPictures.front);
          identityPictures.push(data.identityPictures.back);
          data.identityPictures = identityPictures;
          setData(data);
          setLoading(false);
          data.identityPictures.map((item) => setZoomed([...zoomed, false]));
        })();
      }
    } else {
      history.push("/prescriptions");
    }
  }, [id, history, authReducer, zoomed, loading]);

  const acceptRequest = async () => {
    setAccept(true);
    await put(`${baseUrl}/backend/request/accept/${data._id}`, null, {
      headers: {
        Authorization: `Bearer ${authReducer.token}`,
      },
    });
    let temp = data;
    temp.status = "Accepted";
    setData({ ...temp });
    setAccept(false);
    setAcceptModal(false);
  };
  const rejectRequest = async (req, res) => {
    setReject(true);
    await put(
      `${baseUrl}/backend/request/reject/${data._id}`,
      {
        reasonForRejection: reason,
      },
      {
        headers: {
          Authorization: `Bearer ${authReducer.token}`,
        },
      }
    );
    let temp = data;
    temp.status = "Rejected";
    temp.reasonForRejection = reason;
    setData({ ...temp });
    setReject(false);
    setRejectModal(false);
  };
  const changeAmount = (e) => {
    try {
      const value = parseFloat(e.target.value);
      if (value > data.payment.amount / 100) {
        setAmount(data.payment.amount / 100);
      } else {
        setAmount(value);
      }
    } catch (err) {
      setAmount(amount);
    }
  };
  const refundPayment = async () => {
    try {
      setRefund(true);
      await post(
        `${baseUrl}/backend/request/refund/${data._id}`,
        {
          payment: {
            id: data.payment.id,
            amount: amount * 100,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${authReducer.token}`,
          },
        }
      );
      let temp = data;
      temp.refundStatus = true;
      setData({ ...temp });
      setRejectModal(false);
      setRefund(false);
    } catch (err) {
      setError(err.response.data.msg);
      setRefundSnack(true);
      setRefundModal(false);
      setRefund(false);
    }
  };
  const hideRefundSnack = () => {
    setRefundSnack(false);
  };
  const handleStep = (e, newValue) => {
    setStep(newValue);
  };
  const sendPrescription = () => {
    setAcceptModal(true);
  };
  const openInNewTab = (url) => {
    const win = window.open("about:blank", "_blank");
    const image = new Image();
    image.src = url;
    setTimeout(() => {
      win.document.write(image.outerHTML);
    }, 0);
    win.focus();
  };
  const classes = useStyles();
  return loading ? (
    <Loader />
  ) : (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h5">Prescription Request Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography
                variant="h6"
                className={classes.capitalize}
              >{`${data.firstName.toLowerCase()} ${
                data.middleName ? data.middleName.toLowerCase() : ""
              } ${data.lastName.toLowerCase()}`}</Typography>
            </div>
            {data.status === "Pending" && (
              <div>
                <span style={{ marginRight: 16 }}>
                  {"Status: " + data.status}
                </span>
                <AcceptButton
                  variant="contained"
                  onClick={() => sendPrescription()}
                >
                  Send Prescription
                </AcceptButton>
                <RejectButton
                  variant="contained"
                  onClick={() => setRejectModal(true)}
                >
                  Reject Request
                </RejectButton>
              </div>
            )}
            {data.status !== "Pending" && !data.refundStatus && (
              <>
                <span style={{ marginRight: 16 }}>
                  {/* {"Status: " + data.status === "Accepted"
                    ? "Issued"
                    : data.status} */}
                  {`Status: ${
                    data.status === "Accepted" ? "Issued" : data.status
                  }`}
                </span>
                {data.status === "Accepted" && (
                  <span style={{ marginRight: 16 }}>{`Issued At: ${moment(
                    data.acceptedAt
                  ).format("MMM DD, YYYY hh:mm A")}`}</span>
                )}
                {data.status === "Rejected" && (
                  <span style={{ marginRight: 16 }}>{`Rejected At: ${moment(
                    data.rejectedAt
                  ).format("MMM DD, YYYY hh:mm A")}`}</span>
                )}
                <RejectButton
                  variant="contained"
                  onClick={() => setRefundModal(true)}
                >
                  Refund Amount
                </RejectButton>
              </>
            )}
            {data.status !== "Pending" && data.refundStatus && (
              <>
                <span style={{ marginRight: 16 }}>
                  {`Status: ${
                    data.status === "Accepted" ? "Issued" : data.status
                  }`}
                </span>
                {data.status === "Accepted" && (
                  <span style={{ marginRight: 16 }}>{`Issued At: ${moment(
                    data.acceptedAt
                  ).format("MMM DD, YYYY hh:mm A")}`}</span>
                )}
                {data.status === "Rejected" && (
                  <span style={{ marginRight: 16 }}>{`Rejected At: ${moment(
                    data.rejectedAt
                  ).format("MMM DD, YYYY hh:mm A")}`}</span>
                )}
                <span style={{ marginRight: 16 }}>{`Refunded At: ${moment(
                  data.refundedAt
                ).format("MMM DD, YYYY hh:mm A")}`}</span>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={6} className="flex wrap">
        <Grid item xs={12} md={6}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Patient Information
              </Typography>
              <Spacer mb={4} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item className={classes.right}>
                  Name:{" "}
                </Grid>
                <Grid
                  item
                  className={classes.capitalize}
                >{`${data.firstName.toLowerCase()} ${
                  data.middleName ? data.middleName.toLowerCase() : ""
                } ${data.lastName.toLowerCase()}`}</Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item className={classes.right}>
                  DOB:{" "}
                </Grid>
                <Grid item>{`${moment(data.dob).format("MM/DD/YYYY")}`}</Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item className={classes.right}>
                  Email:{" "}
                </Grid>
                <Grid item>{data.email.toLowerCase()}</Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item className={classes.right}>
                  Phone Number:{" "}
                </Grid>
                <Grid item>{`+1${data.phoneNumber}`}</Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item className={classes.right}>
                  Address:{" "}
                </Grid>
                <Grid
                  item
                  className={classes.capitalize}
                >{`${data.street.toLowerCase()}, ${data.city.toLowerCase()}, ${
                  data.state
                }, ${data.zipcode}`}</Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item className={classes.right}>
                  Pharmacy:{" "}
                </Grid>
                <Grid
                  item
                >{`${data.pharmacy.name} ${data.pharmacy.address}`}</Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card mb={6}>
            <CardContent>
              {/* <Typography variant="h6" gutterBottom>
                Identity
              </Typography> */}
              <Centered>
                <Grid container spacing={6}>
                  <Grid item>
                    <Typography variant="h6" className={classes.right}>
                      Identity Number:
                    </Typography>
                  </Grid>
                  <Grid item>{data.identityNumber}</Grid>
                </Grid>
                <Spacer mb={5} />
                <Grid container spacing={6}>
                  <Grid item xs={12} style={{ padding: "2px 12px" }}>
                    <div style={{ display: "flex" }}>
                      {data.identityPictures?.map((picture, index) => {
                        return (
                          <img
                            src={picture.url}
                            alt="identity"
                            onClick={() => openInNewTab(picture.url)}
                            style={{
                              width: "auto",
                              height: 140,
                              zIndex: 999,
                              cursor: "zoom-in",
                              marginRight: "10px",
                              // objectFit: "cover",
                            }}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Centered>
            </CardContent>
          </Card>
        </Grid>
        {data.allergies.length !== 0 && (
          <Grid item xs={12} md={12}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Allergies
                </Typography>
                <Spacer mb={4} />
                {data.allergies.map((allergy, index) => {
                  return (
                    <Chip
                      label={allergy}
                      key={index}
                      style={{ marginRight: "5px" }}
                    />
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item>
          <Paper>
            <Grid container xs={12} md={12}>
              <Tabs
                value={step}
                onChange={handleStep}
                aria-label="basic tabs"
                className={classes.tabs}
              >
                <Tab label="Questions" {...a11yProps(0)} />
                <Tab label="Patient History" {...a11yProps(0)} />
              </Tabs>
            </Grid>
            <TabPanel value={step} index={0}>
              <Grid item xs={12} md={12}>
                <Card mb={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      {data.questions.map((question, index) => (
                        <>
                          <Grid item xs={2} md={1} key={index.toString()}>
                            <MuiAvatar>{index + 1}</MuiAvatar>
                          </Grid>
                          <Grid item xs={9} md={10} key={index.toString()}>
                            {question.question.question}
                          </Grid>
                          <Grid item xs={1} md={1} key={index.toString()}>
                            {question.answer}
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </TabPanel>
            <Grid container>
              <TabPanel value={step} index={1}>
                <Grid xs={12} md={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow fullWidth>
                          <TableCell>#</TableCell>
                          <TableCell width={250}>Name</TableCell>
                          <TableCell width={250}>Request Time</TableCell>
                          <TableCell width={250}>City</TableCell>
                          <TableCell width={250}>State</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell width={250} align="right">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.history.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <a
                                  href={`/details/${item._id}`}
                                  className={classes.capitalize}
                                >
                                  {`${data.firstName.toLowerCase()} ${
                                    data.middleName
                                      ? data.middleName.toLowerCase()
                                      : ""
                                  } ${data.lastName.toLowerCase()}`}
                                </a>
                              </TableCell>
                              <TableCell>
                                {moment(item.createdAt).format(
                                  "MM/DD/YYYY hh:mm A"
                                )}
                              </TableCell>
                              <TableCell>{item.city}</TableCell>
                              <TableCell>{item.state}</TableCell>

                              <TableCell>
                                {item.status}
                                {item.status === "Rejected"
                                  ? item.refundStatus
                                    ? "- Refunded"
                                    : "- Refund Pending"
                                  : ""}
                              </TableCell>
                              <TableCell align="right">
                                <a href={`/details/${item._id}`}>Details</a>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {loading && (
                          <TableRow>
                            <TableCell>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        )}
                        {!loading && data.history.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={9} align="center">
                              No history found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </TabPanel>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {rejectModal && (
        <Dialog
          open={rejectModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={() => {
            setRejectModal(false);
          }}
        >
          <Paper className={classes.paper}>
            <h2 id="modal-title">Rejection Reason</h2>
            <Grid container spacing={4} rowSpacing={4}>
              <Grid item xs={12}>
                <TextField
                  label="Reason for Rejection"
                  multiline
                  variant="outlined"
                  fullWidth
                  rows={3}
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#FF0000",
                    color: "#FFFFFF",
                  }}
                  onClick={() => rejectRequest()}
                >
                  {reject && <CircularProgress size={24} color="inherit" />}
                  Reject
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
      )}
      {refundModal && (
        <Dialog
          open={refundModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={() => {
            setRefundModal(false);
          }}
        >
          <Paper className={classes.paper}>
            <h2 id="modal-title">Refund Payment</h2>
            <Grid container spacing={4} rowSpacing={4}>
              {data.status === "Rejected" && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    {`Reason for Rejection: ${data.reasonForRejection}`}
                  </Alert>
                </Grid>
              )}
              {data.refundStatus && (
                <Grid item xs={12}>
                  <Alert severity="success">{`Amount refunded`}</Alert>
                </Grid>
              )}
              {!data.refundStatus && (
                <>
                  <Grid xs={12} item>
                    <TextField
                      variant="outlined"
                      label="Amount Received"
                      value={(data.payment.amount / 100).toFixed(2)}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Amount to Refund"
                      onChange={(e) => changeAmount(e)}
                      value={amount}
                      fullWidth
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      InputProps={{
                        inputProps: {
                          min: 0.1,
                          max: data.payment.amount / 100,
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Button
                      variant={"contained"}
                      color="primary"
                      onClick={() => refundPayment()}
                    >
                      {refund && <CircularProgress color="inherit" size={24} />}
                      Refund
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Dialog>
      )}
      {acceptModal && (
        <Dialog
          open={acceptModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={() => {
            setAcceptModal(false);
          }}
        >
          <Paper className={classes.paper}>
            <h2 id="modal-title">Send Prescription</h2>
            <p>
              Please visit{" "}
              <a href="https://mdtoolbox.com" target="_blank" rel="noreferrer">
                MDToolbox
              </a>{" "}
              to send prescription
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => acceptRequest()}
            >
              {accept ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Mark as Sent"
              )}
            </Button>
          </Paper>
        </Dialog>
      )}
      <Snackbar
        open={refundSnack}
        autoHideDuration={6000}
        onClose={hideRefundSnack}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
};

export default Details;
