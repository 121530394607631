export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case "LOGIN":
      return {
        ...state,
        ...actions.payload,
      };

    case "LOGOUT":
      return {
        ...state,
        user: undefined,
        token: undefined,
      };
    case "UPDATE":
      return {
        ...state,
        user: {
          ...state.user,
          name: actions.payload.name,
        },
      };
    case "SET_ZOOMED":
      return {
        ...state,
        zoomed: actions.payload,
      };

    default:
      return state;
  }
}
