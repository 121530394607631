import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import Page404 from "../pages/auth/Page404";
import Signin from "../pages/auth/SignIn";
import Details from "../pages/dashboard/Details";

import { sidebarRoutes } from "../routes/index";
import AuthGuard from "../components/AuthGuard";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;
        const ElementComponent = element.component || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Layout>
                <Guard>
                  <ElementComponent {...props} />
                </Guard>
              </Layout>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Guard>
              <Component {...props} />
            </Guard>
          </Layout>
        )}
      />
    ) : null;
  });

const Routes = () => (
  <>
    <Router>
      <Switch>
        <Route path="/" exact>
          <AuthLayout>
            <Signin />
          </AuthLayout>
        </Route>
        {childRoutes(DashboardLayout, sidebarRoutes)}
        <Route path="/details/:id">
          <AuthGuard>
            <DashboardLayout>
              <Details />
            </DashboardLayout>
          </AuthGuard>
        </Route>
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </Router>
  </>
);

export default Routes;
