/* eslint-disable prettier/prettier */
import axios from "axios";

const api = axios;

const post = async (url, data, header) => {
  return await api.post(url, data, header);
};
const get = async (url, header) => {
  return await api.get(url, header);
};
const put = async (url, data, header) => {
  return await api.put(url, data, header);
};

export { post, get, put };
