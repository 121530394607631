/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import { CreditCard, Sliders, Users, FileText, Settings } from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";

// Dashboards components
const Dashboard = async(() => import("../pages/dashboard/dashboard"));
const Patients = async(() => import("../pages/dashboard/Patients"));
const Payments = async(() => import("../pages/dashboard/Payments"));
const Prescriptions = async(() => import("../pages/dashboard/Prescriptions"));
const Setting = async(() => import("../pages/dashboard/Settings"));

const dashboardsRoute = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Sliders />,
  containsHome: true,
  component: Dashboard,
  guard: AuthGuard,
};

const patient = {
  id: "Patients",
  path: "/patients",
  icon: <Users />,
  containsHome: true,
  component: Patients,
  guard: AuthGuard,
};

const payments = {
  id: "Payments",
  path: "/payments",
  icon: <CreditCard />,
  containsHome: true,
  component: Payments,
  guard: AuthGuard,
};

const prescriptions = {
  id: "Prescriptions",
  path: "/prescriptions",
  icon: <FileText />,
  containsHome: true,
  component: Prescriptions,
  guard: AuthGuard,
};

const settings = {
  id: "Settings",
  path: "/settings",
  icon: <Settings />,
  containsHome: true,
  component: Setting,
  guard: AuthGuard,
};

export const sidebarRoutes = [
  dashboardsRoute,
  prescriptions,
  patient,
  payments,
  settings,
];
